
import { stringRules } from '@/utils/formRules'
import { defineComponent, reactive, ref } from 'vue'
// import Editor from '@/pages/admin/catalog/components/Editor.vue'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useNotification } from '@/composables'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'

export default defineComponent({
  // components: {
  //   Editor,
  // },
  setup() {
    const dialogVisible = ref(false)
    const catalogVariantForm = ref()
    const variantId = ref('')
    const catalogId = ref('')

    const { updateVariant, findOne } = useCatalogVariantRepositories()
    const { getCatalog } = userCatalogRepositories()

    const rules = {
      catalogVariantCode: stringRules('Variant code', 2, 128),
    }

    let state = reactive({
      formVariant: {
        catalogVariantCode: '',
        color: {
          name: '',
          code: '',
          value: '',
        },
        size: {
          name: '',
          code: '',
          value: '',
        },
        status: '',
        description: '',
        cost: '',
        designCost: '',
        shipUsFirst: 0,
        shipUsAdditional: 0,
        shipRowFirst: 0,
        shipRowAdditional: 0,
        supplierCode: {
          code: '',
          quantity: 0,
        },
      },
      designs: ref<any[]>([]),
    })

    const loading = ref(false)
    const { error, success } = useNotification()

    const onSubmit = () => {
      catalogVariantForm.value?.validate(async (isValid: boolean) => {
        if (isValid) {
          const { status, data } = await updateVariant(state.formVariant)
          if (status === 200) {
            close()
            success('Update success')
            window.location.reload()
          } else {
            error(data.message)
          }
        }
      })
    }

    const changeDescription = (html: string, dataSet: any) => {
      dataSet.description = html
    }

    const fetchRecord = async () => {
      loading.value = true
      let { data, status } = await findOne(variantId.value)
      if (status === 200) {
        state.formVariant = { ...state.formVariant, ...data } as any
      }
      loading.value = false
    }

    const fetchCategory = async () => {
      loading.value = true
      let { data, status } = await getCatalog(catalogId.value)
      if (status === 200) {
        console.log('state of variant', data)
        state.designs = data.designs as any
      }
      loading.value = false
    }

    const toggle = (id: string, cid: any) => {
      variantId.value = id
      catalogId.value = cid
      dialogVisible.value = !dialogVisible.value
      fetchRecord()
      fetchCategory()
    }

    const close = () => {
      catalogVariantForm.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      state,
      rules,
      dialogVisible,
      catalogVariantForm,
      loading,
      toggle,
      close,
      onSubmit,
      changeDescription,
    }
  },
})
