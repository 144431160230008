
import { stringRules } from '@/utils/formRules'
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    costBy: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const showErrorMessage = ref(false)
    const state = reactive({
      attribute: {
        name: '',
        value: '',
        code: '',
        cost: 0,
      },
    })
    const rules = {
      name: stringRules('Name', 1, 128),
      value: stringRules('Value', 1, 128),
    }

    const catalogAttribute = ref()
    const dialogVisible = ref(false)

    const onSubmit = () => {
      if (!state.attribute.name.trim() || !state.attribute.value.trim()) {
        showErrorMessage.value = true
        return
      }
      catalogAttribute.value?.validate((isValid: boolean) => {
        if (isValid) {
          state.attribute.code = state.attribute.value // with size: code === value
          context.emit('callbackAttribute', 'size', { ...state.attribute })
          close()
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      catalogAttribute.value?.resetFields()
      dialogVisible.value = false
      showErrorMessage.value = false
    }

    return {
      showErrorMessage,
      state,
      rules,
      dialogVisible,
      catalogAttribute,
      onSubmit,
      toggle,
      close,
    }
  },
})
