
import { useServiceCatalog } from '@/services/useServiceCatalog'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import Editor from '@/pages/admin/catalog/components/Editor.vue'
import DesignTemplate from '@/pages/agency/catalog/component-catalog-basecost/DesignTemplate.vue'
import Variants from '@/pages/agency/catalog/component-catalog-basecost/Variant.vue'
import UploadImage from '@/components/common/UploadImage.vue'
import { useRoute, useRouter } from 'vue-router'
import { reactive, defineComponent, ref, computed, onMounted } from 'vue'
import { Catalog, Variant } from '@/interfaces/catalog'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useCatalogVariantRepositories from '@/repositories/useCatalogVariantRepositories'
import { useFetchData, useNotification } from '@/composables'
import { RefreshLeft } from '@element-plus/icons'
import { CatalogTypeEnum } from '@/utils/catalog'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import { ElMessageBox } from 'element-plus'
import axios from '@/services/axios'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Editor,
    DesignTemplate,
    UploadImage,
    Variants,
    RefreshLeft,
  },
  name: 'Edit Catalog',
  setup() {
    const breadcrumbs = [
      {
        text: 'Catalog',
        link: 'ag.catalogs',
        params: null,
      },
      {
        text: 'Edit',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })
    const costBy = ref('size')
    const catalog = reactive<any>({
      _id: '',
      name: '',
      categories: '',
      productCode: '',
      status: true,
      description: '',
      variants: [],
      sizes: [],
      colors: [],
      shipping: '',
      production: '',
      mockupGallery: [],
      designs: [],
      fulfillmentPartner: [],
    })
    const state = reactive({
      partners: [] as any[],
      locations: [] as any[],
      printAreas: [] as any[],
      techniques: [] as any[],
    })

    const allowCreateCatalog = ref(false)

    const { getCatalog, getFulfillmentList } = userCatalogRepositories()
    const { checkAllowCreateCatalog } = useCatalogRepositories()

    const getAllFulfillmentList = async () => {
      const resource = 'public/catalogs/fulfillments/filters/list'
      const res = (await getFulfillmentList(resource)) as any
      if (res) {
        state.partners = res.partners ?? []
        state.locations = res.locations ?? []
        state.printAreas = res.printAreas ?? []
        state.techniques = res.techniques ?? []
      }
    }

    onMounted(async () => {
      await getAllFulfillmentList()
      await checkAllowCreateCatalogs()
    })
    const checkAllowCreateCatalogs = async () => {
      const res = await checkAllowCreateCatalog()
      allowCreateCatalog.value = res?.allowCreateCatalog
      console.log(`allowCreateCatalog`, allowCreateCatalog.value)
    }

    const resource = `/catalogs`
    const isLoading = ref(true)
    const route = useRoute()
    const router = useRouter()
    const change = ref<boolean>(false)
    const { error, success } = useNotification()
    const addVariants = ref([])
    const deleteIds = ref<string[]>([])
    const params = { page: 1, limit: 1000 }
    const { documents, fetchData } = useFetchData(`/catalog-categories`, params)
    fetchData()

    const activeName = ref('description')

    const getData = async () => {
      isLoading.value = true
      const { status, data } = await getCatalog(route.params.id)
      data.categories = data.categories.map((item: any) => item._id)
      Object.assign(catalog, data)
      console.log('catalog data', data)

      isLoading.value = false
    }
    const variantsTemp = ref<any[]>([])
    getData()
    const changeDescription = (html: string, dataSet: any) => {
      dataSet.description = html
    }
    const changeDescription2 = (html: string, dataSet: any) => {
      dataSet.description2 = html
    }
    const changeSizeGuide = (html: string, dataSet: any) => {
      dataSet.sizeGuide = html
    }
    const changeShippingDetail = (html: string, dataSet: any) => {
      dataSet.shippingDetail = html
    }
    const changePrintGuide = (html: string, dataSet: any) => {
      dataSet.printGuide = html
    }
    const changeService = (html: string, dataSet: any) => {
      dataSet.service = html
    }
    const addDesign = (data: any, dataSet: any, status: any) => {
      if (status.index > -1) {
        dataSet.splice(status.index, 1, data)
      } else {
        dataSet.push(data)
      }
    }
    const callbackMockup = (setData: any, files: string[]) => {
      setData.mockupGallery = files
    }
    const changeProduction = (html: string, dataSet: any) => {
      dataSet.production = html
    }
    const changeShipping = (html: string, dataSet: any) => {
      dataSet.shipping = html
    }
    const changeMaterial = (html: string, dataSet: any) => {
      dataSet.material = html
    }
    const changeCostBy = (value: string) => {
      costBy.value = value
    }
    const delDesign = (index: number | 0) => {
      catalog?.designs?.splice(index, 1)
    }
    // const _idVariants = ref<any[]>([])
    const callbackVariant = (
      colors: any[],
      sizes: any[],
      variants: Variant[],
      // t: string,
      deleteVariantIds: any[]
    ) => {
      catalog.colors = colors
      catalog.sizes = sizes
      change.value = true
      catalog.variants = catalog.variants.concat(variants) //variants
      addVariants.value = addVariants.value.concat(variants as any)
      deleteIds.value = deleteIds.value.concat(deleteVariantIds)
      // _idVariants.value = _id
      variantsTemp.value = [...variants]
    }
    const rulesCatalog = {
      name: [
        {
          required: true,
          message: 'Please input name',
          trigger: 'blur',
        },
      ],
      categories: [
        {
          required: true,
          message: 'Please input category',
          trigger: 'blur',
        },
      ],
      productCode: [
        {
          required: true,
          message: 'Please input product code',
          trigger: 'blur',
        },
      ],
    }
    const isSubmit = ref<boolean>(false)
    const catalogFormUpdate = ref<any>(null)
    const updateCatalog = async () => {
      isSubmit.value = true
      catalogFormUpdate.value?.validate(async (valid: any) => {
        if (valid) {
          const { update } = userCatalogRepositories()
          const { updateVariant, deleteByIdVariant, addVariant } =
            useCatalogVariantRepositories()
          let noIdVariant = catalog.variants?.filter(
            (i: any) => !i._id || i._id === ''
          )
          console.log('noIdVariant', noIdVariant)
          const statusVariant = ref<boolean>(true)
          if (noIdVariant && noIdVariant.length > 0) {
            noIdVariant.forEach(async (i: any) => {
              const { status, data } = await addVariant(
                Object.assign(i, {
                  catalog: catalog._id,
                })
              )
              if (status !== 201) {
                statusVariant.value = false
              }
            })
          }
          // if (_idVariants.value.length > 0) {
          //   _idVariants.value.forEach(async (v: any) => {
          //     if (v.action === 'edit') {
          //       if (catalog.variants?.find((i: any) => i._id === v._id)) {
          //         const { status, data } = await updateVariant(
          //           Object.assign(
          //             catalog.variants.find((i: any) => i._id === v._id),
          //             {
          //               catalog: catalog._id,
          //             }
          //           )
          //         )
          //         if (status !== 200) {
          //           statusVariant.value = false
          //         }
          //       }
          //     } else if (v.action === 'delete') {
          //       const { status, data } = await deleteByIdVariant(v._id)
          //       if (status !== 200) {
          //         statusVariant.value = false
          //       }
          //     }
          //   })
          // }
          console.log('noIdVariant', catalog)
          delete catalog.variants

          //detect IsDrop After Update Type
          catalog.dropship = catalog?.type !== CatalogTypeEnum.pod
          catalog.isTrademark = catalog?.type === CatalogTypeEnum.fake

          const { status, data } = await update(catalog)
          if (status === 200 && statusVariant.value) {
            success('Update Catalog Success!')
            router.push({
              name: 'ag.base-cost.catalog-update',
              params: { id: route.params.id },
            })
            isSubmit.value = false
          }
        }
      })
    }

    const dialog = ref<boolean>(false)
    const cancelDelete = () => {
      dialog.value = false
    }
    const deleteCatalog = () => {
      dialog.value = true
    }
    const confirmDelete = async () => {
      const { deleteById } = userCatalogRepositories()
      const { status, data } = await deleteById(catalog)
      if (status === 200) {
        router.push({ name: 'a.catalogs' })
      }
      console.log('status', status)
    }
    const discard = () => {
      window.location.reload()
    }

    // const info = JSON.parse(localStorage.getItem('info') || '')
    // console.log('info data', info)
    const cloneCatalog = () => {
      const id = route.params.id
      if (!id) {
        error('Not found catalog id')
        return
      }
      ElMessageBox.confirm(
        'Are you sure you want to duplicate this catalog?'
      ).then(async () => {
        const resourceClone = `${resource}/${id}/clone`
        const res = await axios.post(resourceClone)
        if (res?.status === 200 || res.status === 201) {
          success('Duplicate success')
          router.push({ name: 'ag.catalogs' })
        } else {
          error('Error when clone!')
        }
      })
      console.log('cloneCatalog')
    }

    const handleClick = () => {
      console.log('tab clicked')
    }

    return {
      getData,
      route,
      router,
      content,
      catalog,
      costBy,
      changeDescription,
      changeDescription2,
      changeMaterial,
      addDesign,
      callbackMockup,
      changeProduction,
      changeShipping,
      changeCostBy,
      delDesign,
      useServiceCatalog,
      isLoading,
      callbackVariant,
      updateCatalog,
      change,
      variantsTemp,
      // _idVariants,
      dialog,
      cancelDelete,
      deleteCatalog,
      confirmDelete,
      discard,
      isSubmit,
      catalogFormUpdate,
      rulesCatalog,
      documents,
      activeName,
      changeSizeGuide,
      changeShippingDetail,
      changePrintGuide,
      changeService,
      state,
      getAllFulfillmentList,
      handleClick,
      allowCreateCatalog,
      cloneCatalog,
    }
  },
})
